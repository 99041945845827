.container-alt {
  width: 700px;
  margin: 0 auto;

  @media screen and (width <= 740px) {
    width: 100%;
    margin: 0;
  }
}

.logo-container {
  margin: 50px auto;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      height: 42px;
      margin-inline-end: 10px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-text-color;
      text-decoration: none;
      outline: 0;
      padding: 12px 16px;
      line-height: 32px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.compose-standalone {
  .compose-form {
    width: 400px;
    margin: 0 auto;
    padding: 20px 0;
    margin-top: 40px;
    box-sizing: border-box;

    @media screen and (width <= 400px) {
      width: 100%;
      margin-top: 0;
      padding: 20px;
    }
  }
}

.account-header {
  width: 400px;
  margin: 0 auto;
  display: flex;
  font-size: 13px;
  line-height: 18px;
  box-sizing: border-box;
  padding: 20px 0;
  margin-top: 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid $ui-base-color;

  @media screen and (width <= 440px) {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    @include avatar-size(40px);

    margin-inline-end: 10px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
      border-radius: 4px;
      @include avatar-radius;
    }
  }

  .name {
    flex: 1 1 auto;
    color: $secondary-text-color;
    width: calc(100% - 90px);

    .username {
      display: block;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .logout-link {
    display: block;
    font-size: 32px;
    line-height: 40px;
    margin-inline-start: 10px;
  }
}

.redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 14px;
  line-height: 18px;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    img {
      height: 48px;
    }
  }

  &__message {
    text-align: center;

    h1 {
      font-size: 17px;
      line-height: 22px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $highlight-text-color;
      font-weight: 500;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__link {
    margin-top: 15px;
  }
}
